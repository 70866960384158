import { VuexModule, Mutation, Action, getModule, Module } from 'vuex-module-decorators';
import store from '..';
import { getLocal } from '@/lang';

export interface IAppState {
    locale: string,
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements IAppState {
  public locale = getLocal()

  public countryList: any[] = []

  @Mutation
  SET_LANGUAGE(locale: string) {
      this.locale = locale;
      localStorage.setItem('locale', locale);
  }

  @Mutation
  SET_COUNTRY_LIST(list: any[]) {
      this.countryList = list;
  }
}

export const AppModule = getModule(App);
