import Vue from 'vue';
import Vuex from 'vuex';
import { IEGGAlaiState } from '@/store/modules/EGGAlai';
import { IAppState } from '@/store/modules/app';

Vue.use(Vuex);

export interface IRootState {
  App: IAppState
  EGGAlai: IEGGAlaiState
}



export default new Vuex.Store<IRootState>({});
